import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { AppStateContext, InitialAppState } from '../../context/appStateContext'
import { appTimeZone } from '../../globals'
import { useGetAlertSpec } from '../../hooks/useGetAlertSpec'
import { GET_ALERT_HISTORY } from '../../queriesAndMutations/queries/alert.queries'; //TODO change the import
import { Alert } from '../../utils/typedefs'


const AlertHistory: React.FC = () => {
  const alertHistory = useQuery<any>(GET_ALERT_HISTORY, { fetchPolicy: "network-only" });
  const { alertSpecToUsableString } = useGetAlertSpec();
  const [showAll, setShowAll] = useState<boolean>(false)
  const historyNumberToShow = 5;
  const alertHistoryLast5 = alertHistory?.data?.alertHistory?.slice(alertHistory?.data?.alertHistory.length - historyNumberToShow)
  let alertHistoryArray: [] = [];
  let newAlertHistories: Array<string>;

  const { appState, resetAlertHistoryNotifyCountOnDB } = useContext<Partial<InitialAppState>>(AppStateContext);

  if (alertHistory?.data?.alertHistory?.length <= historyNumberToShow) {
    alertHistoryArray = alertHistory?.data?.alertHistory;
  } else {
    alertHistoryArray = showAll ? alertHistory?.data?.alertHistory : alertHistoryLast5;
  }

  const handleAlertHistorySeen = () => {
    resetAlertHistoryNotifyCountOnDB();
  }

  //Get _id's of newly added alerts
  newAlertHistories = (alertHistoryArray?.slice( - appState.alertHistoryNewAlertCount).map((alert: Alert) => alert._id.toString()))



  return (

    <section className='mb-24'>
      <div className="flex flex-row items-center justify-between px-4 pb-2 md:px-0">
        <h2 className="text-xl font-bold text-primary">Alert History</h2>
      </div>
     <div className='card flex flex-col items-center'>
        <table className="min-w-full alert-history-table">
          <thead>
            <tr>
                      <th>
                        Date / Time
                      </th>
                      <th>
                        Meter
                      </th>
                      <th>
                        Farm
                      </th>
                      <th>
                        Alert Trigger
                      </th>
            </tr>
          </thead>
          <tbody>
          {alertHistory?.loading && <tr>
                      <td>
                        Loading Data...
                      </td>

                    </tr>
                    }
                    {!alertHistory.loading && !alertHistory.error && !alertHistory?.data?.alertHistory?.length && (
                      <tr>
                        <td>No Alerts have triggered yet, or you need to add a new Alert below.</td>
                      </tr>
                    )}
                    {alertHistory.error && (
                      <tr>
                        <td>{alertHistory?.error?.message}</td>
                      </tr>
                    )}
                    {!alertHistory.loading && !alertHistory.error && alertHistoryArray?.map((history: any, idx: Number) => (
                      <tr key={history._id}
                        style={appState?.alertHistoryNewAlertCount && newAlertHistories?.find((newAlertId: string) => history._id === newAlertId) ? { backgroundColor: "#97dbf1" } : {}}
                        className="odd:bg-secondary/5 hover:bg-secondary/10">
                         <td className='text-left'>
                          {moment.tz(history?.triggerTime, appTimeZone).format('ddd DD MMM YYYY hh:mm a')}
                        </td>
                        <td className='text-left'>
                          {history?.alert?.meter?.name}
                        </td>
                        <td className='text-left'>
                          {history?.alert?.farm?.name}
                        </td>
                        <td className='text-left'>
                          Usage {alertSpecToUsableString(history?.alert?.alertSpec)} exceeded <span><b>{history?.alert?.volume} m3</b></span>
                        </td>
                      </tr>
                    ))}
          </tbody>
        </table>
        {appState?.alerts?.length > 0 && !appState?.allAlertHistoryAlertsSeen && <div className='min-w-full flex flex-row align-center justify-center'>
              <a onClick={handleAlertHistorySeen} className=" cursor-pointer hover:text-lg text-md mb-4 mt-4 font-bold text-primary underline underline-offset-4">
                Acknowledge Alert(s)
              </a>
            </div>}
            {alertHistory?.data?.alertHistory?.length > 5 && <div className="alert-history-showall flex justify-center items-center ">
              <div className="p-4 px-6">
                <button
                  className="button-base disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray "
                  type="submit"
                  disabled={alertHistory?.data?.alertHistory?.length < 1}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? "Show Last 5 Alerts" : "Show All Alerts"}
                </button>
              </div>
            </div>}
      </div>
    </section>
  )
}

export default AlertHistory
